// noinspection ES6UnusedImports
import {Component, h} from "preact";
import {TieredTech, TieredTechItem} from "./TieredTech";
import data from "../../external/aoe2techtree/data/data.json"
import {civHelpText, itemHelp, itemName} from "../aoe2renderutils";
import {
  CARAVEL,
  CONDOTTIERO,
  ELITE_CARAVEL,
  ELITE_GENITOUR,
  ELITE_LONGBOAT,
  ELITE_TURTLE_SHIP,
  GENITOUR,
  LONGBOAT,
  MISSIONARY,
  SLINGER,
  TURTLE_SHIP
} from "../aoe2utils";

const SPECIAL_UNITS = {
  "Incas": [SLINGER],
  "Berbers": [GENITOUR, ELITE_GENITOUR],
  "Italians": [CONDOTTIERO],
  "Spanish": [MISSIONARY],
  "Portuguese": [CARAVEL, ELITE_CARAVEL],
  "Vikings": [LONGBOAT, ELITE_LONGBOAT],
  "Koreans": [TURTLE_SHIP, ELITE_TURTLE_SHIP]
}

export class TechList extends Component {
  render({civ, civName, hasVietnameseTeamMember = false}) {
    const helpTexts = civHelpText(civName);
    const cost = (t,x) => {
      const item = data.data[`${t}s`][x];
      return Object.entries(item.Cost).map(([key, value]) => `${value}${key[0]}`).join(" ")
    }

    let specialUnit = null;

    if (civName in SPECIAL_UNITS) {
      specialUnit = new TieredTechItem("unit", [], SPECIAL_UNITS[civName]);
    }

    return <div class="tech-list">
      <TieredTech civ={civ} item={TieredTechItem.UNIT_MILITIA} class="melee"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_SPEARMAN} class="melee"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_EAGLE_SCOUT} class="melee"/>
      <div style="grid-column: auto / span 1" class="melee"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_MELEE_ATTACK} class="melee"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_MELEE_ARMOR} class="melee"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_CAVALRY_ARMOR} class="melee"/>

      <TieredTech civ={civ} item={TieredTechItem.UNIT_ARCHER} class="ranged"/>
      <TieredTech civ={civ} hasVietnameseTeamMember={hasVietnameseTeamMember} item={TieredTechItem.UNIT_SKIRMISHER}
                  class="ranged"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_CAVALRY_ARCHER} class="ranged"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_HAND_CANNONEER} class="ranged"/>

      <TieredTech civ={civ} item={TieredTechItem.TECH_RANGED_ATTACK} class="ranged"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_RANGED_ARMOR} class="ranged"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_THUMB_RING} class="ranged"/>

      <TieredTech civ={civ} item={TieredTechItem.UNIT_SCOUT} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_KNIGHT} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_CAMEL_RIDER} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_BATTLE_ELEPHANT} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_STEPPE_LANCER} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_HUSBANDRY} class="cavalry"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_BLOODLINES} class="cavalry"/>

      <TieredTech civ={civ} item={TieredTechItem.UNIT_BATTERING_RAM} class="siege"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_MANGONEL} class="siege"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_SCORPION} class="siege"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_BOMBARD_CANNON} class="siege"/>

      <TieredTech civ={civ} item={TieredTechItem.TECH_SIEGE_ENGINEERS} class="siege"/>
      <TieredTech civ={civ} item={TieredTechItem.BUILDING_GUARD_TOWER} class="siege"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_PARTHIAN_TACTICS} class="siege"/>

      <TieredTech civ={civ} item={TieredTechItem.UNIT_GALLEY} class="water"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_FIRE_GALLEY} class="water"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_DEMOLITION_RAFT} class="water"/>
      <TieredTech civ={civ} item={TieredTechItem.UNIT_CANNON_GALLEON} class="water"/>
      <div style="grid-column: auto / span 1" class="water"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_REDEMPTION} class="water"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_HERESY} class="water"/>

      <TieredTech civ={civ} item={TieredTechItem.TECH_WOODCUTTING} class="eco"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_FARMING} class="eco"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_GOLD_MINING} class="eco"/>
      <TieredTech civ={civ} item={TieredTechItem.TECH_STONE_MINING} class="eco"/>
      <div style="grid-column: auto / span 1" class="eco"/>
      {specialUnit ? <TieredTech civ={civ}
                                 item={specialUnit}
                                 class="eco"/> : <div class="eco"></div>}
      <TieredTech civ={civ}
                  item={new TieredTechItem("unit", [], [civ.uniqueConfig.uniqueUnit, civ.uniqueConfig.uniqueUnitElite])}
                  class="eco"/>

      <div class="info-panel">
        <b>Civ bonuses:</b>
        {helpTexts.generic.map(x => <p>{x}</p>)}
        <b>Team Bonus:</b>
        <p>{helpTexts.team_bonus}</p>
      </div>
      <div class="info-panel">
        <b>Civ unique unit:</b>
        <p>
          <i>{itemName("unit", civ.uniqueConfig.uniqueUnit)} ({cost("unit", civ.uniqueConfig.uniqueUnit)})</i>
          <p>
          {itemHelp("unit", civ.uniqueConfig.uniqueUnit).split("<br>", 2).pop().split('<i>').shift()}
          </p>
        </p>
        <b>Civ techs:</b>
        <p>
          <i>{itemName("tech", civ.uniqueConfig.uniqueTechOne)} ({cost("tech", civ.uniqueConfig.uniqueTechOne)})</i>
          <p>
            {itemHelp("tech", civ.uniqueConfig.uniqueTechOne).split("<br>", 2).pop()}
          </p>
          <i>{itemName("tech", civ.uniqueConfig.uniqueTechTwo)} ({cost("tech",civ.uniqueConfig.uniqueTechTwo)})</i>
          <p>
            {itemHelp("tech", civ.uniqueConfig.uniqueTechTwo).split("<br>", 2).pop()}
          </p>
        </p>
      </div>
    </div>
  }
}