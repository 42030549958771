import data from "../external/aoe2techtree/data/data.json"
import {specialUnits, specialUpgrades} from "./aoe2utils";

export function itemName(type, id) {
  const typeSet = data.data[`${type}s`];
  if (typeSet === undefined) {
    return "Untitled"
  }

  const objectInfo = typeSet[id]
  if (objectInfo === undefined || objectInfo.LanguageNameId === undefined) {
    return "Untitled"
  }

  return data.strings[objectInfo.LanguageNameId] || "Untitled"
}

export function itemHelp(type, id) {
  const typeSet = data.data[`${type}s`];
  if (typeSet === undefined) {
    return ""
  }

  const objectInfo = typeSet[id]
  if (objectInfo === undefined || objectInfo.LanguageHelpId === undefined) {
    return ""
  }

  return data.strings[objectInfo.LanguageHelpId] || ""
}

export function civHelpText(name) {
  const helpText = data.strings[data.civ_helptexts[name]]
  let parts = helpText.split("<b>");
  const helpParts = {generic: parts.shift()}
  for (const part of parts) {
    let [name, text] = part.split("</b>")
    helpParts[name.toLowerCase().replace(':', '').toLowerCase().replace(/\s+/g, '_')] = text;
  }

  let firstNewLine = helpParts.generic.indexOf("\n");
  helpParts.type = helpParts.generic.substr(0, firstNewLine);
  helpParts.generic = helpParts.generic.substr(firstNewLine);

  for (const key in helpParts) {
    helpParts[key] = helpParts[key]
      .replace(/^(\s+|<br>)+/, '')
      .replace(/(\s+|<br>)+$/, '');

    if (key === "generic" || key == "unique_techs") {
      helpParts[key] = Array.from(helpParts[key].matchAll(/•([^\n]+)/g)).map(x => x[1].trim().replace(/(\s+|<br>)+$/, ''));
    }
  }

  return helpParts
}

export function color(type, id, civ = null) {
  if (specialUpgrades.includes(id) || specialUnits.includes(id) || (civ !== null && (civ.uniqueConfig.uniqueUnit === id || civ.uniqueConfig.uniqueUnitElite === id))) {
    return "purple"
  }

  switch (type) {
    case "unit":
      return "blue"
    case "building":
      return "red"
    case "tech":
      return "green"
  }

  return "purple"
}

function pick(object, keys = []) {
  return keys.reduce((cum, val) => {
    if (val in object) {
      cum[val] = object[val]
    }
    return cum;
  }, {})
}

export class PlayerCache {
  map = new Map();
  lastAccessed = []
  onUpdate = []
  callbackId = 0;

  onUpdateOf(id, call) {
    const x = {cId: this.callbackId++, profile_id: id, call};
    this.onUpdate.push(x);
    return x.cId
  }

  onUpdateOfSteamId(id, call) {
    const x = {cId: this.callbackId++, steam_id: id, call};
    this.onUpdate.push(x);
    return x.cId
  }

  removeCallback(id) {
    this.onUpdate = this.onUpdate.filter(x => x.cId !== id);
  }

  triggerOnUpdate(info) {
    const triggers = this.onUpdate.filter(x => {
      return (x.profile_id === info.profile_id) || (x.steam_id === info.steam_id && !!info.steam_id);
    })

    triggers.forEach(x => x.call(info));
  }

  registerPlayer(info, fetchFull = true) {
    const id = info.profile_id;
    let changed = false;
    info = pick(info, ["clan", "rating", "name", "country", "profile_id", "steam_id"])

    if (!fetchFull) {
      info.is_full = true;
    }

    if (this.map.has(id)) {
      const obj = this.map.get(id);
      for (const key in info) {
        if (key === "last_match") {
          continue;
        }

        if (info[key] === null) {
          continue
        }

        if (obj[key] !== info[key]) {
          changed = true;
          obj[key] = info[key]
        }
      }

      info = obj;
    } else {
      if (this.lastAccessed.length > 50) {
        const toDelete = this.lastAccessed.pop();
        this.map.delete(toDelete);
      }

      this.map.set(id, info);
      if (!this.lastAccessed.includes(id)) {
        this.lastAccessed.unshift(id);
      }

      changed = true;
    }

    if (!info.is_full) {
      this.fetchPlayerInfo(id);
    }

    if (changed) {
      this.triggerOnUpdate(info);
    }
  }

  async fetchPlayerInfo(id, forced = true) {
    const resp = await fetch(`https://aoe2buddy.eater.me/last_match?profile_id=${id}`)
    const data = await resp.json();
    if (!data) {
      return;
    }

    if (!this.map.has(id) && !forced) {
      return;
    }

    this.registerPlayer(data, false);
    return this.map.get(id);
  }

  completeInstant(info) {
    if (this.map.has(info.profile_id)) {
      this.lastAccessed.splice(this.lastAccessed.indexOf(info.profile_id), 1);
      this.lastAccessed.unshift(info.profile_id);
      return this.map.get(info.profile_id)
    }

    return info;
  }

  async getPlayer(id) {
    if (this.map.has(id)) {
      this.lastAccessed.splice(this.lastAccessed.indexOf(id), 1);
      this.lastAccessed.unshift(id);
      return this.map.get(id)
    }

    return await this.fetchPlayerInfo(id, true);
  }
}

export function genN(n, map) {
  let i = 0;

  let it = {
    next() {
      if (i < n) {
        return {done: false, value: map(i++)}
      }

      return {done: true}
    },
    [Symbol.iterator]() {
      return it;
    }
  }

  return Array.from(it)
}