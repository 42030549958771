// noinspection ES6UnusedImports
import {Component, Fragment, h} from "preact";
import civs from "../../img/aoe2techtree/Civs/*.png"
import {TieredTech, TieredTechItem} from "./TieredTech";
import {civsConfig, parseCiv} from "../aoe2utils";
import {civHelpText, itemName} from "../aoe2renderutils";

export class TeamTable extends Component {
  render({teams, strings, playerCache}, state, context) {
    return <table class="teams">
      <tbody>
      {Object.keys(teams).map(nr => <TeamDisplay playerCache={playerCache} team={nr} players={teams[nr]}
                                                 strings={strings}/>)}
      </tbody>
    </table>
  }
}

export class TeamPlayer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {playerInfo: props.player};
  }

  async componentDidMount() {
    this.setState({playerInfo: await this.props.playerCache.getPlayer(this.props.player.profile_id)});
    this.callbackId = this.props.playerCache.onUpdateOf(this.props.player.profile_id, (info) => this.setState({playerInfo: info}));
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    this.props.playerCache.removeCallback(this.callbackId);
    this.setState({playerInfo: await nextProps.playerCache.getPlayer(nextProps.player.profile_id)});
    this.callbackId = this.props.playerCache.onUpdateOf(nextProps.player.profile_id, (info) => this.setState({playerInfo: info}));
  }

  componentWillUnmount() {
    this.props.playerCache.removeCallback(this.callbackId);
  }

  render({player, strings, hasVietnameseTeamMember = false}, {playerInfo = {}}, context) {
    let playerCiv = strings.civ[player.civ].string;
    const helpText = civHelpText(playerCiv);
    let civ = parseCiv(civsConfig[playerCiv]);
    return <Fragment>
      <tr class="team-player">
        <td rowspan="2" class={`player-color player-color${player.color}`}>{player.color}</td>
        <td>{playerInfo.country || <i>n.a.</i>}</td>
        <td>{player.name}</td>
        <td>{player.rating || playerInfo.rating || <i>n.a.</i>}</td>
        <td class="team-civ">
          <img class="team-civ-icon" src={civs[playerCiv.toLowerCase()]}/>
          {playerCiv}
        </td>
        <td>
          <i class="team-civ-type">{helpText.type.replace(" civilization", "")}</i>
        </td>
        <td class="team-bonus">
          {helpText.team_bonus}
        </td>
        <td>
          {itemName("unit", civ.uniqueConfig.uniqueUnit)}
        </td>
        <td class="special-unit">
          <TieredTech withoutBar={true} withoutText={true} civ={civ}
                      item={new TieredTechItem("unit", [], [civ.uniqueConfig.uniqueUnit])}/>
        </td>
      </tr>
      <tr class="team-player">
        <td colspan="999" class="mini-tech-list">
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_MILITIA} class="melee"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_SPEARMAN} class="melee"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_EAGLE_SCOUT} class="melee"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_ARCHER} class="ranged"/>
          <TieredTech hasVietnameseTeamMember={hasVietnameseTeamMember} withoutText={true} civ={civ}
                      item={TieredTechItem.UNIT_SKIRMISHER} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_CAVALRY_ARCHER} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_HAND_CANNONEER} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_SCOUT} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_KNIGHT} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_CAMEL_RIDER} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_BATTLE_ELEPHANT} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_STEPPE_LANCER} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_BATTERING_RAM} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_MANGONEL} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_SCORPION} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_BOMBARD_CANNON} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_GALLEY} class="water"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_FIRE_GALLEY} class="water"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_DEMOLITION_RAFT} class="water"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.UNIT_CANNON_GALLEON} class="water"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_MELEE_ATTACK} class="melee"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_MELEE_ARMOR} class="melee"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_CAVALRY_ARMOR} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_RANGED_ATTACK} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_RANGED_ARMOR} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_THUMB_RING} class="ranged"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_PARTHIAN_TACTICS} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_HUSBANDRY} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_BLOODLINES} class="cavalry"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_SIEGE_ENGINEERS} class="siege"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_REDEMPTION} class="water"/>
          <TieredTech withoutText={true} civ={civ} item={TieredTechItem.TECH_HERESY} class="water"/>
        </td>
      </tr>
    </Fragment>
  }
}

export class TeamDisplay extends Component {
  cIds = {};

  constructor() {
    super();
  }

  static getDerivedStateFromProps({playerCache, players}) {
    let completedPlayers = players.map(x => playerCache.completeInstant(x));
    return {players: completedPlayers, teamName: TeamDisplay.getTeamName(completedPlayers)}
  }

  componentDidUpdate(previousProps, previousState, snapshot) {
    let newCIds = {};
    for (const player of this.state.players) {
      if (player.profile_id in this.cIds) {
        newCIds[player.profile_id] = this.cIds[player.profile_id]
        delete this.cIds[player.profile_id];
      } else {
        newCIds[player.profile_id] = this.props.playerCache.onUpdateOf(player.profile_id, info => this.updatePlayer(info));
      }
    }

    for (const cId in this.cIds) {
      this.props.playerCache.removeCallback(this.cIds[cId])
    }

    this.cIds = newCIds
  }

  componentWillUnmount() {
    for (const cId in this.cIds) {
      this.props.playerCache.removeCallback(this.cIds[cId])
    }
  }

  updatePlayer(info) {
    this.setState((prevState) => {
      let prevPlayer = prevState.players.find(x => x.profile_id === info.profile_id);
      Object.assign(prevPlayer, info);
      prevState.teamName = TeamDisplay.getTeamName(prevState.players);
      return prevState;
    })
  }

  static getTeamName(players) {
    const clanName = players[0].clan;
    if (clanName !== null) {
      for (const player of players) {
        const theirClan = player.clan;
        if (theirClan !== clanName) {
          break;
        }
      }
    }

    return clanName;
  }

  render({team, playerCache, players, strings}, {teamName}, context) {
    const vietnamese = Array.from(Object.values(strings.civ)).find(x => x.string === "Vietnamese").id;
    const hasVietnameseTeamMember = players.some(x => x.civ === vietnamese);

    return <Fragment>
      <tr class="team-title">
        <th colspan="999">{teamName === null ? `Team ${team}` : teamName}</th>
      </tr>
      {players.map(player => <TeamPlayer playerCache={playerCache} hasVietnameseTeamMember={hasVietnameseTeamMember}
                                         player={player} strings={strings}/>)}
    </Fragment>
  }
}
