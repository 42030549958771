import "babel-polyfill"
import {civsConfig, parseCiv} from "./aoe2utils";
// noinspection ES6UnusedImports
import {Component, h, render} from "preact";
import {TechList} from "./component/TechList";
import {TeamTable} from "./component/TeamDisplay";
import civs from "../img/aoe2techtree/Civs/*.png"
import {civHelpText, PlayerCache} from "./aoe2renderutils";
import strings from "../strings.json"

class App extends Component {
  componentDidMount() {
    this.props.playerCache.onUpdateOfSteamId(this.props.steamId, (player) => this.setState({player}))
    this.updateMatch();
  }

  async updateMatch() {
    this.setState(state => Object.assign(state, {refreshing: true}))
    let resp = await fetch("https://aoe2buddy.eater.me/last_match?steam_id=" + this.props.steamId);

    if (resp.status !== 200) {
      this.setState(state => Object.assign(state, {match: false, refreshing: false}))
      setTimeout(() => this.updateMatch(), 15_000);
      return;
    }

    let json = await resp.json();

    this.setState(state => Object.assign(state, {match: json, refreshing: false}));

    if (json) {
      this.props.playerCache.registerPlayer(Object.assign({}, json), false);
      for (const player of json.last_match.players) {
        this.props.playerCache.registerPlayer(Object.assign({}, player));
      }
    }
    setTimeout(() => this.updateMatch(), json.last_match.finished ? 15_000 : 60_000);
  }

  render({steamId, playerCache}, {match, refreshing, player = undefined}, context) {
    if ((!match && match !== false) || !player) {
      return <div>Loading...</div>
    }

    if (match === false) {
      return <div>No latest match found{refreshing ? ' (refreshing data...)' : null}</div>
    }

    const ownPlayer = match.last_match.players.find(x => player.profile_id === x.profile_id);
    const ownCiv = strings.civ[ownPlayer.civ].string;
    const vietnamese = Array.from(Object.values(strings.civ)).find(x => x.string === "Vietnamese").id;

    const teams = match
      .last_match
      .players
      .reduce((cum, item) => {
        cum[item.team] = (cum[item.team] || []).concat([item]);
        return cum;
      }, {});

    const civHelp = civHelpText(ownCiv);
    const hasVietnameseTeamMember = (teams[ownPlayer.team] || []).some(x => x.civ == vietnamese);

    return (
      <div class="app">
        <div class="player-table">
          <h1 class="player-name">{ownPlayer.name} <small class="name-civ"><img class="civ-logo"
                                                                                src={civs[ownCiv.toLowerCase()]}
                                                                                alt={`Logo of the ${ownCiv}`}/>
            {ownCiv} - {civHelp.type}</small></h1>
          <TechList hasVietnameseTeamMember={hasVietnameseTeamMember} civ={parseCiv(civsConfig[ownCiv])}
                    civName={ownCiv}/>
          {refreshing ? <small>(refreshing data...)</small> : null}
        </div>
        <TeamTable playerCache={playerCache} teams={teams} strings={strings}/>
      </div>
    );
  }
}

render(<div>
  <App playerCache={new PlayerCache()} steamId={location.hash ? location.hash.substr(1) : "76561198058603365"}/>
</div>, document.getElementById("app"))