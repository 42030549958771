// noinspection ES6UnusedImports
import {Component, h} from "preact";
import {
  ARBALESTER,
  ARCHER,
  ARCHITECTURE,
  BATTERING_RAM,
  BATTLE_ELEPHANT,
  BLAST_FURNACE, BLOODLINES,
  BODKIN_ARROW,
  BOMBARD_CANNON,
  BOW_SAW,
  BRACER,
  CAMEL_RIDER,
  CANNON_GALLEON,
  CAPPED_RAM,
  CAVALIER,
  CAVALRY_ARCHER,
  CHAIN_BARDING_ARMOR,
  CHAIN_MAIL_ARMOR,
  CHAMPION,
  CROP_ROTATION,
  CROSSBOWMAN,
  DEMOLITION_RAFT,
  DEMOLITION_SHIP,
  DOUBLE_BIT_AXE,
  EAGLE_SCOUT,
  EAGLE_WARRIOR,
  ELITE_BATTLE_ELEPHANT,
  ELITE_CANNON_GALLEON,
  ELITE_EAGLE_WARRIOR,
  ELITE_SKIRMISHER, ELITE_STEPPE_LANCER,
  FAST_FIRE_SHIP,
  FIRE_GALLEY,
  FIRE_SHIP,
  FLETCHING,
  FORGING,
  GALLEON,
  GALLEY,
  GOLD_MINING,
  GOLD_SHAFT_MINING,
  GUARD_TOWER,
  HALBERDIER,
  HAND_CANNONEER,
  HEAVY_CAMEL_RIDER,
  HEAVY_CAV_ARCHER,
  HEAVY_DEMO_SHIP,
  HEAVY_PLOW,
  HEAVY_SCORPION, HERESY,
  HORSE_COLLAR, HUSBANDRY,
  HUSSAR,
  IMPERIAL_CAMEL_RIDER,
  IMPERIAL_SKIRMISHER,
  IRON_CASTING,
  KEEP,
  KNIGHT,
  LEATHER_ARCHER_ARMOR,
  LIGHT_CAVALRY,
  LONG_SWORDSMAN,
  MANGONEL,
  MASONRY,
  ONAGER,
  PADDED_ARCHER_ARMOR,
  PALADIN,
  PARTHIAN_TACTICS,
  PIKEMAN,
  PLATE_BARDING_ARMOR,
  PLATE_MAIL_ARMOR,
  REDEMPTION,
  RING_ARCHER_ARMOR,
  SCALE_BARDING_ARMOR,
  SCALE_MAIL_ARMOR,
  SCORPION,
  SCOUT_CAVALRY, SIEGE_ENGINEERS,
  SIEGE_ONAGER,
  SIEGE_RAM,
  SKIRMISHER,
  SPEARMAN, STEPPE_LANCER,
  STONE_MINING,
  STONE_SHAFT_MINING, THUMB_RING,
  TWO_HANDED_SWORDSMAN,
  TWO_MAN_SAW,
  WAR_GALLEY,
  WATCH_TOWER
} from "../aoe2utils";

import {color, genN,  itemName as name} from "../aoe2renderutils"
import {Buildings, Techs, Units} from "../../img/aoe2techtree/*/*.png"

const imgMap = {"unit": Units, "building": Buildings, "tech": Techs}

export class TieredTechItem {
  static TECH_MELEE_ATTACK = new TieredTechItem("tech", [FORGING, IRON_CASTING, BLAST_FURNACE])
  static TECH_MELEE_ARMOR = new TieredTechItem("tech", [SCALE_MAIL_ARMOR, CHAIN_MAIL_ARMOR, PLATE_MAIL_ARMOR])
  static TECH_RANGED_ATTACK = new TieredTechItem("tech", [FLETCHING, BODKIN_ARROW, BRACER])
  static TECH_RANGED_ARMOR = new TieredTechItem("tech", [PADDED_ARCHER_ARMOR, LEATHER_ARCHER_ARMOR, RING_ARCHER_ARMOR])
  static TECH_CAVALRY_ARMOR = new TieredTechItem("tech", [SCALE_BARDING_ARMOR, CHAIN_BARDING_ARMOR, PLATE_BARDING_ARMOR])
  static TECH_MASONRY = new TieredTechItem("tech", [MASONRY, ARCHITECTURE])
  static TECH_GOLD_MINING = new TieredTechItem("tech", [GOLD_MINING, GOLD_SHAFT_MINING])
  static TECH_STONE_MINING = new TieredTechItem("tech", [STONE_MINING, STONE_SHAFT_MINING])
  static TECH_WOODCUTTING = new TieredTechItem("tech", [DOUBLE_BIT_AXE, BOW_SAW, TWO_MAN_SAW])
  static TECH_FARMING = new TieredTechItem("tech", [HORSE_COLLAR, HEAVY_PLOW, CROP_ROTATION])
  static TECH_REDEMPTION = new TieredTechItem("tech", [REDEMPTION])
  static TECH_THUMB_RING = new TieredTechItem("tech", [THUMB_RING])
  static TECH_PARTHIAN_TACTICS = new TieredTechItem("tech", [PARTHIAN_TACTICS])
  static TECH_BLOODLINES = new TieredTechItem("tech", [BLOODLINES])
  static TECH_SIEGE_ENGINEERS = new TieredTechItem("tech", [SIEGE_ENGINEERS])
  static TECH_HUSBANDRY = new TieredTechItem("tech", [HUSBANDRY])
  static TECH_HERESY = new TieredTechItem("tech", [HERESY])

  static BUILDING_GUARD_TOWER = new TieredTechItem("building", [WATCH_TOWER, GUARD_TOWER, KEEP])

  static UNIT_ARCHER = new TieredTechItem("unit", [ARCHER, CROSSBOWMAN, ARBALESTER])
  static UNIT_SKIRMISHER = new TieredTechItem("unit", [SKIRMISHER, ELITE_SKIRMISHER], [IMPERIAL_SKIRMISHER])
  static UNIT_CAVALRY_ARCHER = new TieredTechItem("unit", [CAVALRY_ARCHER, HEAVY_CAV_ARCHER])
  static UNIT_HAND_CANNONEER = new TieredTechItem("unit", [HAND_CANNONEER])
  static UNIT_MILITIA = new TieredTechItem("unit", [LONG_SWORDSMAN, TWO_HANDED_SWORDSMAN, CHAMPION])
  static UNIT_SPEARMAN = new TieredTechItem("unit", [SPEARMAN, PIKEMAN, HALBERDIER])
  static UNIT_EAGLE_SCOUT = new TieredTechItem("unit", [EAGLE_SCOUT, EAGLE_WARRIOR, ELITE_EAGLE_WARRIOR])
  static UNIT_SCOUT = new TieredTechItem("unit", [SCOUT_CAVALRY, LIGHT_CAVALRY, HUSSAR])
  static UNIT_KNIGHT = new TieredTechItem("unit", [KNIGHT, CAVALIER, PALADIN])
  static UNIT_CAMEL_RIDER = new TieredTechItem("unit", [CAMEL_RIDER, HEAVY_CAMEL_RIDER], [IMPERIAL_CAMEL_RIDER])
  static UNIT_BATTLE_ELEPHANT = new TieredTechItem("unit", [BATTLE_ELEPHANT, ELITE_BATTLE_ELEPHANT])
  static UNIT_STEPPE_LANCER = new TieredTechItem("unit", [STEPPE_LANCER, ELITE_STEPPE_LANCER]);
  static UNIT_BATTERING_RAM = new TieredTechItem("unit", [BATTERING_RAM, CAPPED_RAM, SIEGE_RAM])
  static UNIT_MANGONEL = new TieredTechItem("unit", [MANGONEL, ONAGER, SIEGE_ONAGER])
  static UNIT_SCORPION = new TieredTechItem("unit", [SCORPION, HEAVY_SCORPION])
  static UNIT_BOMBARD_CANNON = new TieredTechItem("unit", [BOMBARD_CANNON])
  static UNIT_FIRE_GALLEY = new TieredTechItem("unit", [FIRE_GALLEY, FIRE_SHIP, FAST_FIRE_SHIP])
  static UNIT_CANNON_GALLEON = new TieredTechItem("unit", [CANNON_GALLEON, ELITE_CANNON_GALLEON])
  static UNIT_DEMOLITION_RAFT = new TieredTechItem("unit", [DEMOLITION_RAFT, DEMOLITION_SHIP, HEAVY_DEMO_SHIP])
  static UNIT_GALLEY = new TieredTechItem("unit", [GALLEY, WAR_GALLEY, GALLEON])

  /**
   * @param {string} type
   * @param {number[]} normal
   * @param {number[]} special
   */
  constructor(type, normal, special = []) {
    this.type = type
    this.normal = normal;
    this.special = special;
  }

  highest(civ, {hasVietnameseTeamMember = false} = {}) {
    let items = ({building: civ.disabledBuildings, unit: civ.disabledUnits, tech: civ.disabledTechs})[this.type]
    let last = null;
    for (const item of this.normal) {
      if (items.includes(item)) {
        return last
      }

      last = item
    }

    for (const item of this.special) {
      if (items.includes(item) && !(item === IMPERIAL_SKIRMISHER && hasVietnameseTeamMember)) {
        return last;
      }

      last = item
    }

    return last
  }

  first() {
    return this.normal[0]
  }

  amount() {
    return this.normal.length
  }

  /**
   *
   * @param {Civ} civ
   */
  supports(civ, {hasVietnameseTeamMember = false} = {}) {
    let items = ({building: civ.disabledBuildings, unit: civ.disabledUnits, tech: civ.disabledTechs})[this.type]
    let i = 0;
    for (const item of this.normal) {
      if (items.includes(item)) {
        return i
      }

      i++
    }

    for (const item of this.special) {
      if (items.includes(item) && !(item === IMPERIAL_SKIRMISHER && hasVietnameseTeamMember)) {
        return i;
      }

      i++
    }

    return i
  }
}

export class TieredTech extends Component {
  /**
   *
   * @param {TieredTechItem} item
   * @param {Civ} civ
   * @param state
   * @param context
   */
  render({item, hasVietnameseTeamMember = false, withoutText = false, withoutBar = false, civ, "class": clazz, ...props}, state, context) {
    let enabled = true;
    let highest = item.highest(civ, {hasVietnameseTeamMember});
    if (highest === null) {
      highest = item.first();
      enabled = false;
    }

    let supports = item.supports(civ, {hasVietnameseTeamMember});
    let amount = item.amount();
    let bars = Math.max(amount, supports)
    return <div {...props}
                class={`tiered-tech block-${enabled ? color(item.type, highest, civ) : 'grey disabled'} ${clazz || ""}`}>
      <div class="tech-image-container">
        <div class="tech-image" style={`background-image: url(${imgMap[item.type][highest]})`}/>
        {enabled ? null : <div class="tech-cross"/>}
      </div>
      { withoutText ? null : <span class="name">{name(item.type, highest)}</span> }
      { withoutBar ? null : <div class="tier-bar">
        {genN(bars, (i) => <div key={i}
                                class={`tier-bar-part ${supports <= i ? 'red' : (amount <= i ? 'gold' : 'green')}`}/>)}
      </div>  }
    </div>
  }
}