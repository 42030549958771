export const TYPES = Object.freeze({
  "BUILDING": {colour: '#922602', type: 'BUILDING', name: 'Building'},
  "UNIT": {colour: '#3a6a80', type: 'UNIT', name: 'Unit'},
  "UNIQUEUNIT": {colour: '#af30a3', type: 'UNIQUEUNIT', name: 'Unique Unit'},
  "TECHNOLOGY": {colour: '#2c5729', type: 'TECHNOLOGY', name: 'Technology'}
});

export const PREFIX = Object.freeze({
  "BUILDING": 'building_',
  "UNIT": 'unit_',
  "UNIQUEUNIT": 'unit_',
  "TECHNOLOGY": 'tech_'
});

export const UNIQUE_UNIT = "UNIQUE UNIT";
export const ELITE_UNIQUE_UNIT = "ELITE UNIQUE UNIT";
export const UNIQUE_TECH_1 = "UNIQUE TECH 1";
export const UNIQUE_TECH_2 = "UNIQUE TECH 2";
export const MONK_PREFIX_MESO = "meso_";
export const MONK_PREFIX_AFRICAN = "african_";
export const MONK_PREFIX_ASIAN = "asian_";
export const MONK_PREFIX_GENERIC = "";
export const BARRACKS = 12;
export const DOCK = 45;
export const SIEGE_WORKSHOP = 49;
export const FARM = 50;
export const MILL = 68;
export const HOUSE = 70;
export const PALISADE_WALL = 72;
export const GATE = 78;
export const WATCH_TOWER = 79;
export const CASTLE = 82;
export const MARKET = 84;
export const ARCHERY_RANGE = 87;
export const STABLE = 101;
export const BLACKSMITH = 103;
export const MONASTERY = 104;
export const TOWN_CENTER = 109;
export const STONE_WALL = 117;
export const FORTIFIED_WALL = 155;
export const FISH_TRAP = 199;
export const UNIVERSITY = 209;
export const GUARD_TOWER = 234;
export const KEEP = 235;
export const BOMBARD_TOWER = 236;
export const WONDER = 276;
export const LUMBER_CAMP = 562;
export const MINING_CAMP = 584;
export const OUTPOST = 598;
export const PALISADE_GATE = 790;
export const FEITORIA = 1021;
export const KREPOST = 1251;
export const ARCHER = 4;
export const HAND_CANNONEER = 5;
export const ELITE_SKIRMISHER = 6;
export const SKIRMISHER = 7;
export const LONGBOWMAN = 8;
export const MANGUDAI = 11;
export const FISHING_SHIP = 13;
export const TRADE_COG = 17;
export const WAR_GALLEY = 21;
export const CROSSBOWMAN = 24;
export const TEUTONIC_KNIGHT = 25;
export const BATTERING_RAM = 35;
export const BOMBARD_CANNON = 36;
export const KNIGHT = 38;
export const CAVALRY_ARCHER = 39;
export const CATAPHRACT = 40;
export const HUSKARL = 41;
export const TREBUCHET = 42;
export const JANISSARY = 46;
export const CHU_KO_NU = 73;
export const MILITIA = 74;
export const MAN_AT_ARMS = 75;
export const LONG_SWORDSMAN = 77;
export const VILLAGER = 83;
export const SPEARMAN = 93;
export const MONK = 125;
export const TRADE_CART = 128;
export const SLINGER = 185;
export const IMPERIAL_CAMEL_RIDER = 207;
export const WOAD_RAIDER = 232;
export const WAR_ELEPHANT = 239;
export const LONGBOAT = 250;
export const SCORPION = 279;
export const MANGONEL = 280;
export const THROWING_AXEMAN = 281;
export const MAMELUKE = 282;
export const CAVALIER = 283;
export const SAMURAI = 291;
export const CAMEL_RIDER = 329;
export const HEAVY_CAMEL_RIDER = 330;
export const PIKEMAN = 358;
export const HALBERDIER = 359;
export const CANNON_GALLEON = 420;
export const CAPPED_RAM = 422;
export const PETARD = 440;
export const HUSSAR = 441;
export const GALLEON = 442;
export const SCOUT_CAVALRY = 448;
export const TWO_HANDED_SWORDSMAN = 473;
export const HEAVY_CAV_ARCHER = 474;
export const ARBALESTER = 492;
export const DEMOLITION_SHIP = 527;
export const HEAVY_DEMO_SHIP = 528;
export const FIRE_SHIP = 529;
export const ELITE_LONGBOWMAN = 530;
export const ELITE_THROWING_AXEMAN = 531;
export const FAST_FIRE_SHIP = 532;
export const ELITE_LONGBOAT = 533;
export const ELITE_WOAD_RAIDER = 534;
export const GALLEY = 539;
export const HEAVY_SCORPION = 542;
export const TRANSPORT_SHIP = 545;
export const LIGHT_CAVALRY = 546;
export const SIEGE_RAM = 548;
export const ONAGER = 550;
export const ELITE_CATAPHRACT = 553;
export const ELITE_TEUTONIC_KNIGHT = 554;
export const ELITE_HUSKARL = 555;
export const ELITE_MAMELUKE = 556;
export const ELITE_JANISSARY = 557;
export const ELITE_WAR_ELEPHANT = 558;
export const ELITE_CHU_KO_NU = 559;
export const ELITE_SAMURAI = 560;
export const ELITE_MANGUDAI = 561;
export const CHAMPION = 567;
export const PALADIN = 569;
export const SIEGE_ONAGER = 588;
export const ELITE_CANNON_GALLEON = 691;
export const BERSERK = 692;
export const ELITE_BERSERK = 694;
export const JAGUAR_WARRIOR = 725;
export const ELITE_JAGUAR_WARRIOR = 726;
export const EAGLE_SCOUT = 751;
export const ELITE_EAGLE_WARRIOR = 752;
export const EAGLE_WARRIOR = 753;
export const TARKAN = 755;
export const ELITE_TARKAN = 757;
export const PLUMED_ARCHER = 763;
export const ELITE_PLUMED_ARCHER = 765;
export const CONQUISTADOR = 771;
export const ELITE_CONQUISTADOR = 773;
export const MISSIONARY = 775;
export const WAR_WAGON = 827;
export const ELITE_WAR_WAGON = 829;
export const TURTLE_SHIP = 831;
export const ELITE_TURTLE_SHIP = 832;
export const GENOESE_CROSSBOWMAN = 866;
export const ELITE_GENOESE_CROSSBOWMAN = 868;
export const MAGYAR_HUSZAR = 869;
export const ELITE_MAGYAR_HUSZAR = 871;
export const ELEPHANT_ARCHER = 873;
export const ELITE_ELEPHANT_ARCHER = 875;
export const BOYAR = 876;
export const ELITE_BOYAR = 878;
export const KAMAYUK = 879;
export const ELITE_KAMAYUK = 881;
export const CONDOTTIERO = 882;
export const ORGAN_GUN = 1001;
export const ELITE_ORGAN_GUN = 1003;
export const CARAVEL = 1004;
export const ELITE_CARAVEL = 1006;
export const CAMEL_ARCHER = 1007;
export const ELITE_CAMEL_ARCHER = 1009;
export const GENITOUR = 1010;
export const ELITE_GENITOUR = 1012;
export const GBETO = 1013;
export const ELITE_GBETO = 1015;
export const SHOTEL_WARRIOR = 1016;
export const ELITE_SHOTEL_WARRIOR = 1018;
export const FIRE_GALLEY = 1103;
export const DEMOLITION_RAFT = 1104;
export const SIEGE_TOWER = 1105;
export const BALLISTA_ELEPHANT = 1120;
export const ELITE_BALLISTA_ELEPHANT = 1122;
export const KARAMBIT_WARRIOR = 1123;
export const ELITE_KARAMBIT_WARRIOR = 1125;
export const ARAMBAI = 1126;
export const ELITE_ARAMBAI = 1128;
export const RATTAN_ARCHER = 1129;
export const ELITE_RATTAN_ARCHER = 1131;
export const BATTLE_ELEPHANT = 1132;
export const ELITE_BATTLE_ELEPHANT = 1134;
export const IMPERIAL_SKIRMISHER = 1155;
export const KESHIK = 1228;
export const ELITE_KESHIK = 1230;
export const KIPCHAK = 1231;
export const ELITE_KIPCHAK = 1233;
export const LEITIS = 1234;
export const ELITE_LEITIS = 1236;
export const DISMOUNTED_KONNIK = 1252;
export const DISMOUNTED_ELITE_KONNIK = 1253;
export const KONNIK = 1254;
export const ELITE_KONNIK = 1255;
export const FLAMING_CAMEL = 1263;
export const STEPPE_LANCER = 1370;
export const ELITE_STEPPE_LANCER = 1372;
export const XOLOTL_WARRIOR = 1570;
export const YEOMEN = 3;
export const EL_DORADO = 4;
export const FUROR_CELTICA = 5;
export const DRILL = 6;
export const MAHOUTS = 7;
export const TOWN_WATCH = 8;
export const ZEALOTRY = 9;
export const ARTILLERY = 10;
export const CRENELLATIONS = 11;
export const CROP_ROTATION = 12;
export const HEAVY_PLOW = 13;
export const HORSE_COLLAR = 14;
export const GUILDS = 15;
export const ANARCHY = 16;
export const BANKING = 17;
export const ATHEISM = 21;
export const LOOM = 22;
export const COINAGE = 23;
export const GARLAND_WARS = 24;
export const HUSBANDRY = 39;
export const FAITH = 45;
export const CHEMISTRY = 47;
export const CARAVAN = 48;
export const BERSERKERGANG = 49;
export const MASONRY = 50;
export const ARCHITECTURE = 51;
export const ROCKETRY = 52;
export const TREADMILL_CRANE = 54;
export const GOLD_MINING = 55;
export const KATAPARUTO = 59;
export const LOGISTICA = 61;
export const KEEP_TECH = 63;
export const BOMBARD_TOWER_TECH = 64;
export const GILLNETS = 65;
export const FORGING = 67;
export const IRON_CASTING = 68;
export const SCALE_MAIL_ARMOR = 74;
export const BLAST_FURNACE = 75;
export const CHAIN_MAIL_ARMOR = 76;
export const PLATE_MAIL_ARMOR = 77;
export const PLATE_BARDING_ARMOR = 80;
export const SCALE_BARDING_ARMOR = 81;
export const CHAIN_BARDING_ARMOR = 82;
export const BEARDED_AXE = 83;
export const BALLISTICS = 93;
export const FEUDAL_AGE = 101;
export const CASTLE_AGE = 102;
export const IMPERIAL_AGE = 103;
export const GUARD_TOWER_TECH = 140;
export const GOLD_SHAFT_MINING = 182;
export const FORTIFIED_WALL_TECH = 194;
export const FLETCHING = 199;
export const BODKIN_ARROW = 200;
export const BRACER = 201;
export const DOUBLE_BIT_AXE = 202;
export const BOW_SAW = 203;
export const PADDED_ARCHER_ARMOR = 211;
export const LEATHER_ARCHER_ARMOR = 212;
export const WHEELBARROW = 213;
export const SQUIRES = 215;
export const RING_ARCHER_ARMOR = 219;
export const TWO_MAN_SAW = 221;
export const BLOCK_PRINTING = 230;
export const SANCTITY = 231;
export const ILLUMINATION = 233;
export const HAND_CART = 249;
export const FERVOR = 252;
export const STONE_MINING = 278;
export const STONE_SHAFT_MINING = 279;
export const TOWN_PATROL = 280;
export const CONSCRIPTION = 315;
export const REDEMPTION = 316;
export const ATONEMENT = 319;
export const SAPPERS = 321;
export const MURDER_HOLES = 322;
export const SHIPWRIGHT = 373;
export const CAREENING = 374;
export const DRY_DOCK = 375;
export const SIEGE_ENGINEERS = 377;
export const HOARDINGS = 379;
export const HEATED_SHOT = 380;
export const SPIES_TREASON = 408;
export const BLOODLINES = 435;
export const PARTHIAN_TACTICS = 436;
export const THUMB_RING = 437;
export const THEOCRACY = 438;
export const HERESY = 439;
export const SUPREMACY = 440;
export const HERBAL_MEDICINE = 441;
export const SHINKICHON = 445;
export const PERFUSION = 457;
export const ATLATL = 460;
export const WARWOLF = 461;
export const GREAT_WALL = 462;
export const CHIEFTAINS = 463;
export const GREEK_FIRE = 464;
export const STRONGHOLD = 482;
export const MARAUDERS = 483;
export const YASAMA = 484;
export const OBSIDIAN_ARROWS = 485;
export const PANOKSEON = 486;
export const NOMADS = 487;
export const KAMANDARAN = 488;
export const IRONCLAD = 489;
export const MADRASAH = 490;
export const SIPAHI = 491;
export const INQUISITION = 492;
export const CHIVALRY = 493;
export const PAVISE = 494;
export const SILK_ROAD = 499;
export const SULTANS = 506;
export const SHATAGNI = 507;
export const ORTHODOXY = 512;
export const DRUZHINA = 513;
export const CORVINIAN_ARMY = 514;
export const RECURVE_BOW = 515;
export const ANDEAN_SLING = 516;
export const FABRIC_SHIELDS = 517;
export const CARRACK = 572;
export const ARQUEBUS = 573;
export const ROYAL_HEIRS = 574;
export const TORSION_ENGINES = 575;
export const TIGUI = 576;
export const FARIMBA = 577;
export const KASBAH = 578;
export const MAGHRABI_CAMELS = 579;
export const ARSON = 602;
export const ARROWSLITS = 608;
export const TUSK_SWORDS = 622;
export const DOUBLE_CROSSBOW = 623;
export const THALASSOCRACY = 624;
export const FORCED_LEVY = 625;
export const HOWDAH = 626;
export const MANIPUR_CAVALRY = 627;
export const CHATRAS = 628;
export const PAPER_MONEY = 629;
export const STIRRUPS = 685;
export const BAGAINS = 686;
export const SILK_ARMOR = 687;
export const TIMURID_SIEGECRAFT = 688;
export const STEPPE_HUSBANDRY = 689;
export const CUMAN_MERCENARIES = 690;
export const HILL_FORTS = 691;
export const TOWER_SHIELDS = 692;
export const SUPPLIES = 716;


export const horseDisabledBuildings = [STABLE];
export const horseDisabledUnits = [SCOUT_CAVALRY, LIGHT_CAVALRY, HUSSAR, KNIGHT, PALADIN, CAMEL_RIDER,
  HEAVY_CAMEL_RIDER, CAVALIER, CAVALRY_ARCHER, HEAVY_CAV_ARCHER];
export const horseDisabledTechs = [BLOODLINES, HUSBANDRY, SCALE_BARDING_ARMOR, CHAIN_BARDING_ARMOR,
  PLATE_BARDING_ARMOR, PARTHIAN_TACTICS];

export const defaultDisabledUnits = [EAGLE_SCOUT, EAGLE_WARRIOR, ELITE_EAGLE_WARRIOR, BATTLE_ELEPHANT,
  ELITE_BATTLE_ELEPHANT, STEPPE_LANCER, ELITE_STEPPE_LANCER, FLAMING_CAMEL, XOLOTL_WARRIOR];

export const defaultDisabledBuildings = [KREPOST, FEITORIA,];

export const specialUpgrades = [IMPERIAL_SKIRMISHER, IMPERIAL_CAMEL_RIDER];
export const specialUnits = [SLINGER, GENITOUR, ELITE_GENITOUR, CONDOTTIERO, MISSIONARY, CARAVEL, ELITE_CARAVEL, LONGBOAT, ELITE_LONGBOAT, TURTLE_SHIP, ELITE_TURTLE_SHIP];

/**
 * @param civ
 * @returns {Civ}
 */
export function parseCiv(civ) {
  let builder = new CivBuilder({
    uniqueUnit: civ.unique[0],
    uniqueUnitElite: civ.unique[1],
    uniqueTechOne: civ.unique[2],
    uniqueTechTwo: civ.unique[3],
    monkPrefix: civ.monkPrefix
  });

  if (civ.disabled) {
    if (civ.disabled.buildings) {
      builder.disableBuildings(civ.disabled.buildings);
    }
    if (civ.disabled.units) {
      builder.disableUnits(civ.disabled.units);
    }
    if (civ.disabled.techs) {
      builder.disableTechs(civ.disabled.techs);
    }
  }

  if (civ.enabled) {
    if (civ.enabled.buildings) {
      builder.enableBuildings(civ.enabled.buildings);
    }
    if (civ.enabled.units) {
      builder.enableUnits(civ.enabled.units);
    }
    if (civ.enabled.techs) {
      builder.enableTechs(civ.enabled.techs);
    }
  }

  if (civ.disableHorses) builder.disableHorses();

  return builder.build();
}

class Civ {
  constructor(buildings, techs, units, monkPrefix, uniqueConfig) {
    this.disabledBuildings = buildings || [];
    this.disabledTechs = techs || [];
    this.disabledUnits = units || [];
    this.monkPrefix = monkPrefix;
    this.uniqueConfig = uniqueConfig;
  }
}

class CivBuilder {
  constructor(uniqueConfig) {
    this.disabledBuildings = [
      // Unique Buildings
      KREPOST,
      FEITORIA,
    ];
    this.disabledUnits = [
      // Units that are not often enabled
      BATTLE_ELEPHANT,
      ELITE_BATTLE_ELEPHANT,
      STEPPE_LANCER,
      ELITE_STEPPE_LANCER,
      EAGLE_SCOUT,
      EAGLE_WARRIOR,
      ELITE_EAGLE_WARRIOR,
      // Unique units
      SLINGER,
      IMPERIAL_SKIRMISHER,
      GENITOUR,
      ELITE_GENITOUR,
      CONDOTTIERO,
      IMPERIAL_CAMEL_RIDER,
      XOLOTL_WARRIOR,
      TURTLE_SHIP,
      ELITE_TURTLE_SHIP,
      LONGBOAT,
      ELITE_LONGBOAT,
      CARAVEL,
      ELITE_CARAVEL,
      FLAMING_CAMEL,
      KONNIK,
      ELITE_KONNIK,
      MISSIONARY,
    ];
    this.disabledTechs = [];
    this.uniqueUnit = uniqueConfig.uniqueUnit;
    this.uniqueUnitElite = uniqueConfig.uniqueUnitElite;
    this.uniqueTechOne = uniqueConfig.uniqueTechOne;
    this.uniqueTechTwo = uniqueConfig.uniqueTechTwo;
    this.monkPrefix = uniqueConfig.monkPrefix;
  }

  disableHorses() {
    this.disabledBuildings = this.disabledBuildings.concat([STABLE]);
    this.disabledTechs = this.disabledTechs.concat([
      BLOODLINES,
      HUSBANDRY,
      SCALE_BARDING_ARMOR,
      CHAIN_BARDING_ARMOR,
      PLATE_BARDING_ARMOR,
      PARTHIAN_TACTICS,
    ]);
    this.disabledUnits = this.disabledUnits.concat([
      SCOUT_CAVALRY,
      LIGHT_CAVALRY,
      HUSSAR,
      KNIGHT,
      PALADIN,
      CAMEL_RIDER,
      HEAVY_CAMEL_RIDER,
      CAVALIER,
      CAVALRY_ARCHER,
      HEAVY_CAV_ARCHER,
    ]);
    return this;
  }

  disableBuildings(toDisable) {
    this.disabledBuildings = this.disabledBuildings.concat(toDisable);
    return this;
  }

  disableUnits(toDisable) {
    this.disabledUnits = this.disabledUnits.concat(toDisable);
    return this;
  }

  disableTechs(toDisable) {
    this.disabledTechs = this.disabledTechs.concat(toDisable);
    return this;
  }

  enableUnits(toEnable) {
    for (let enable of toEnable) {
      this.disabledUnits.splice(this.disabledUnits.indexOf(enable), 1)
    }
    return this;
  }

  enableBuildings(toEnable) {
    for (let enable of toEnable) {
      this.disabledBuildings.splice(this.disabledBuildings.indexOf(enable), 1)
    }
    return this;
  }

  enableTechs(toEnable) {
    for (let enable of toEnable) {
      this.disabledTechs.splice(this.disabledTechs.indexOf(enable), 1)
    }
    return this;
  }

  build() {
    return new Civ(this.disabledBuildings,
      this.disabledTechs,
      this.disabledUnits,
      this.monkPrefix,
      {
        uniqueUnit: this.uniqueUnit,
        uniqueUnitElite: this.uniqueUnitElite,
        uniqueTechOne: this.uniqueTechOne,
        uniqueTechTwo: this.uniqueTechTwo,
      })
  }
}

export const civsConfig = {
  Aztecs: {
    disableHorses: true,
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        HOARDINGS,
        RING_ARCHER_ARMOR,
        MASONRY,
        ARCHITECTURE,
        BOMBARD_TOWER_TECH,
        KEEP_TECH,
        TWO_MAN_SAW,
        GUILDS
      ],
      units: [
        HAND_CANNONEER,
        HALBERDIER,
        CANNON_GALLEON,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP,
        GALLEON,
        HEAVY_SCORPION,
        BOMBARD_CANNON
      ]
    },
    enabled: {
      units: [
        EAGLE_SCOUT,
        EAGLE_WARRIOR,
        ELITE_EAGLE_WARRIOR,
        XOLOTL_WARRIOR,
      ]
    },
    monkPrefix: MONK_PREFIX_MESO,
    unique: [
      JAGUAR_WARRIOR,
      ELITE_JAGUAR_WARRIOR,
      ATLATL,
      GARLAND_WARS
    ]
  },
  Berbers: {
    disabled: {
      buildings: [
        BOMBARD_TOWER,
        KEEP
      ],
      techs: [
        PARTHIAN_TACTICS,
        SHIPWRIGHT,
        SANCTITY,
        BLOCK_PRINTING,
        SAPPERS,
        ARCHITECTURE,
        BOMBARD_TOWER_TECH,
        KEEP_TECH,
        TWO_MAN_SAW
      ],
      units: [
        ARBALESTER,
        HALBERDIER,
        PALADIN,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    enabled: {
      units: [
        GENITOUR,
        ELITE_GENITOUR
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      CAMEL_ARCHER,
      ELITE_CAMEL_ARCHER,
      KASBAH,
      MAGHRABI_CAMELS
    ]
  },
  Britons: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        BLOODLINES,
        REDEMPTION,
        ATONEMENT,
        HERESY,
        BOMBARD_TOWER_TECH,
        TREADMILL_CRANE,
        STONE_SHAFT_MINING,
        CROP_ROTATION
      ],
      units: [
        HAND_CANNONEER,
        HUSSAR,
        PALADIN,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        ELITE_CANNON_GALLEON,
        SIEGE_RAM,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    unique: [
      LONGBOWMAN,
      ELITE_LONGBOWMAN,
      YEOMEN,
      WARWOLF
    ]
  },
  Bulgarians: {
    disabled: {
      buildings: [
        FORTIFIED_WALL,
        BOMBARD_TOWER
      ],
      techs: [
        RING_ARCHER_ARMOR,
        DRY_DOCK,
        SHIPWRIGHT,
        FORTIFIED_WALL_TECH,
        TREADMILL_CRANE,
        ARROWSLITS,
        BOMBARD_TOWER_TECH,
        HOARDINGS,
        SAPPERS,
        ATONEMENT,
        SANCTITY,
        FAITH,
        BLOCK_PRINTING,
        TWO_MAN_SAW,
        GUILDS
      ],
      units: [
        CROSSBOWMAN,
        ARBALESTER,
        HAND_CANNONEER,
        CHAMPION,
        PALADIN,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        BOMBARD_CANNON,
        FAST_FIRE_SHIP,
        HEAVY_DEMO_SHIP,
        ELITE_CANNON_GALLEON
      ]
    },
    enabled: {
      buildings: [
        KREPOST
      ],
      units: [
        KONNIK,
        ELITE_KONNIK
      ]
    },
    unique: [
      KONNIK,
      ELITE_KONNIK,
      STIRRUPS,
      BAGAINS
    ]
  },
  Burmese: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        SHIPWRIGHT,
        HERESY,
        HOARDINGS,
        SAPPERS,
        LEATHER_ARCHER_ARMOR,
        RING_ARCHER_ARMOR,
        BOMBARD_TOWER_TECH,
        ARROWSLITS,
        STONE_SHAFT_MINING
      ],
      units: [
        ARBALESTER,
        HAND_CANNONEER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        FAST_FIRE_SHIP,
        HEAVY_DEMO_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    enabled: {
      units: [
        BATTLE_ELEPHANT,
        ELITE_BATTLE_ELEPHANT
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      ARAMBAI,
      ELITE_ARAMBAI,
      HOWDAH,
      MANIPUR_CAVALRY
    ]
  },
  Byzantines: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        BLOODLINES,
        HERBAL_MEDICINE,
        SAPPERS,
        BLAST_FURNACE,
        MASONRY,
        ARCHITECTURE,
        SIEGE_ENGINEERS,
        HEATED_SHOT,
        TREADMILL_CRANE
      ],
      units: [
        HEAVY_SCORPION,
        SIEGE_ONAGER
      ]
    },
    unique: [
      CATAPHRACT,
      ELITE_CATAPHRACT,
      GREEK_FIRE,
      LOGISTICA
    ]
  },
  Celts: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        SQUIRES,
        BLOODLINES,
        REDEMPTION,
        ILLUMINATION,
        ATONEMENT,
        BLOCK_PRINTING,
        THEOCRACY,
        RING_ARCHER_ARMOR,
        BRACER,
        PLATE_BARDING_ARMOR,
        ARCHITECTURE,
        BOMBARD_TOWER_TECH,
        TWO_MAN_SAW,
        CROP_ROTATION
      ],
      units: [
        ARBALESTER,
        HAND_CANNONEER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        FAST_FIRE_SHIP,
        ELITE_CANNON_GALLEON,
        BOMBARD_CANNON
      ]
    },
    unique: [
      WOAD_RAIDER,
      ELITE_WOAD_RAIDER,
      STRONGHOLD,
      FUROR_CELTICA
    ]
  },
  Chinese: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        HERESY,
        REDEMPTION,
        HOARDINGS,
        SIEGE_ENGINEERS,
        TREADMILL_CRANE,
        GUILDS,
        CROP_ROTATION,
        SUPPLIES,
      ],
      units: [
        HAND_CANNONEER,
        HUSSAR,
        PALADIN,
        FAST_FIRE_SHIP,
        ELITE_CANNON_GALLEON,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      CHU_KO_NU,
      ELITE_CHU_KO_NU,
      GREAT_WALL,
      ROCKETRY
    ]
  },
  Cumans: {
    disabled: {
      buildings: [
        GATE,
        STONE_WALL,
        FORTIFIED_WALL,
        GUARD_TOWER,
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        BRACER,
        DRY_DOCK,
        SHIPWRIGHT,
        FORTIFIED_WALL_TECH,
        GUARD_TOWER_TECH,
        TREADMILL_CRANE,
        ARCHITECTURE,
        SIEGE_ENGINEERS,
        KEEP_TECH,
        ARROWSLITS,
        BOMBARD_TOWER_TECH,
        ILLUMINATION,
        REDEMPTION,
        BLOCK_PRINTING,
        THEOCRACY,
        STONE_SHAFT_MINING,
        HUSBANDRY,
        SUPPLIES,
      ],
      units: [
        ARBALESTER,
        HAND_CANNONEER,
        HEAVY_CAMEL_RIDER,
        HEAVY_SCORPION,
        BOMBARD_CANNON,
        CANNON_GALLEON,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP
      ]
    },
    enabled: {
      units: [
        STEPPE_LANCER,
        ELITE_STEPPE_LANCER
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      KIPCHAK,
      ELITE_KIPCHAK,
      STEPPE_HUSBANDRY,
      CUMAN_MERCENARIES
    ]
  },
  Ethiopians: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        PARTHIAN_TACTICS,
        BLOODLINES,
        REDEMPTION,
        BLOCK_PRINTING,
        HOARDINGS,
        PLATE_BARDING_ARMOR,
        TREADMILL_CRANE,
        ARROWSLITS,
        BOMBARD_TOWER_TECH,
        CROP_ROTATION
      ],
      units: [
        HAND_CANNONEER,
        CHAMPION,
        PALADIN,
        FAST_FIRE_SHIP,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      SHOTEL_WARRIOR,
      ELITE_SHOTEL_WARRIOR,
      ROYAL_HEIRS,
      TORSION_ENGINES
    ]
  },
  Franks: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        BLOODLINES,
        SHIPWRIGHT,
        REDEMPTION,
        ATONEMENT,
        SAPPERS,
        RING_ARCHER_ARMOR,
        BRACER,
        HEATED_SHOT,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        STONE_SHAFT_MINING,
        TWO_MAN_SAW,
        GUILDS
      ],
      units: [
        ARBALESTER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        HUSSAR,
        ELITE_CANNON_GALLEON,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    unique: [
      THROWING_AXEMAN,
      ELITE_THROWING_AXEMAN,
      CHIVALRY,
      BEARDED_AXE
    ]
  },
  Goths: {
    disabled: {
      buildings: [
        GUARD_TOWER,
        KEEP,
        BOMBARD_TOWER,
        GATE,
        STONE_WALL,
        FORTIFIED_WALL
      ],
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        DRY_DOCK,
        GUARD_TOWER_TECH,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        FORTIFIED_WALL_TECH,
        REDEMPTION,
        ATONEMENT,
        BLOCK_PRINTING,
        HERESY,
        HOARDINGS,
        PLATE_BARDING_ARMOR,
        PLATE_MAIL_ARMOR,
        SIEGE_ENGINEERS,
        TREADMILL_CRANE,
        ARROWSLITS,
        GOLD_SHAFT_MINING,
        SUPPLIES,
        ARSON,
      ],
      units: [
        ARBALESTER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        ELITE_CANNON_GALLEON,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    unique: [
      HUSKARL,
      ELITE_HUSKARL,
      ANARCHY,
      PERFUSION
    ]
  },
  Huns: {
    disabled: {
      buildings: [
        GUARD_TOWER,
        KEEP,
        BOMBARD_TOWER,
        FORTIFIED_WALL,
        HOUSE
      ],
      techs: [
        SHIPWRIGHT,
        GUARD_TOWER_TECH,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        REDEMPTION,
        HERBAL_MEDICINE,
        BLOCK_PRINTING,
        THEOCRACY,
        HOARDINGS,
        RING_ARCHER_ARMOR,
        PLATE_MAIL_ARMOR,
        FORTIFIED_WALL_TECH,
        HEATED_SHOT,
        TREADMILL_CRANE,
        ARCHITECTURE,
        SIEGE_ENGINEERS,
        ARROWSLITS,
        STONE_SHAFT_MINING,
        CROP_ROTATION,
        SUPPLIES,
      ],
      units: [
        ARBALESTER,
        HAND_CANNONEER,
        CHAMPION,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        FAST_FIRE_SHIP,
        CANNON_GALLEON,
        ELITE_CANNON_GALLEON,
        ONAGER,
        SIEGE_ONAGER,
        HEAVY_SCORPION,
        BOMBARD_CANNON
      ]
    },
    unique: [
      TARKAN,
      ELITE_TARKAN,
      MARAUDERS,
      ATHEISM
    ]
  },
  Incas: {
    disableHorses: true,
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        BOMBARD_TOWER_TECH,
        ATONEMENT,
        FERVOR,
        ARCHITECTURE,
        TWO_MAN_SAW
      ],
      units: [
        HAND_CANNONEER,
        CANNON_GALLEON,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    enabled: {
      units: [
        EAGLE_SCOUT,
        EAGLE_WARRIOR,
        ELITE_EAGLE_WARRIOR,
        SLINGER,
        XOLOTL_WARRIOR,
      ]
    },
    monkPrefix: MONK_PREFIX_MESO,
    unique: [
      KAMAYUK,
      ELITE_KAMAYUK,
      ANDEAN_SLING,
      FABRIC_SHIELDS
    ]
  },
  Indians: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        SHIPWRIGHT,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        ATONEMENT,
        HERESY,
        SAPPERS,
        PLATE_MAIL_ARMOR,
        ARCHITECTURE,
        ARROWSLITS,
        TREADMILL_CRANE,
        CROP_ROTATION
      ],
      units: [
        ARBALESTER,
        KNIGHT,
        CAVALIER,
        PALADIN,
        FAST_FIRE_SHIP,
        HEAVY_SCORPION,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    enabled: {
      units: [
        IMPERIAL_CAMEL_RIDER
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      ELEPHANT_ARCHER,
      ELITE_ELEPHANT_ARCHER,
      SULTANS,
      SHATAGNI
    ]
  },
  Italians: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        HERESY,
        SAPPERS,
        SIEGE_ENGINEERS,
        GOLD_SHAFT_MINING
      ],
      units: [
        HEAVY_CAV_ARCHER,
        HALBERDIER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        HEAVY_DEMO_SHIP,
        HEAVY_SCORPION,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    enabled: {
      units: [
        CONDOTTIERO
      ]
    },
    unique: [
      GENOESE_CROSSBOWMAN,
      ELITE_GENOESE_CROSSBOWMAN,
      PAVISE,
      SILK_ROAD
    ]
  },
  Japanese: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        BOMBARD_TOWER_TECH,
        HERESY,
        HOARDINGS,
        SAPPERS,
        PLATE_BARDING_ARMOR,
        ARCHITECTURE,
        HEATED_SHOT,
        STONE_SHAFT_MINING,
        GUILDS,
        CROP_ROTATION
      ],
      units: [
        HUSSAR,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        HEAVY_DEMO_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      SAMURAI,
      ELITE_SAMURAI,
      YASAMA,
      KATAPARUTO
    ]
  },
  Khmer: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        SQUIRES,
        BOMBARD_TOWER_TECH,
        ATONEMENT,
        HERESY,
        BLOCK_PRINTING,
        SHIPWRIGHT,
        PLATE_MAIL_ARMOR,
        ARROWSLITS,
        TWO_MAN_SAW,
        GUILDS,
        SUPPLIES,
      ],
      units: [
        CHAMPION,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        HEAVY_DEMO_SHIP,
        SIEGE_ONAGER,
        BOMBARD_CANNON,
      ]
    },
    enabled: {
      units: [
        BATTLE_ELEPHANT,
        ELITE_BATTLE_ELEPHANT
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      BALLISTA_ELEPHANT,
      ELITE_BALLISTA_ELEPHANT,
      TUSK_SWORDS,
      DOUBLE_CROSSBOW
    ]
  },
  Koreans: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        BLOODLINES,
        REDEMPTION,
        ATONEMENT,
        HERESY,
        ILLUMINATION,
        HOARDINGS,
        SAPPERS,
        BLAST_FURNACE,
        PLATE_BARDING_ARMOR,
        CROP_ROTATION
      ],
      units: [
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        ELITE_CANNON_GALLEON,
        DEMOLITION_RAFT,
        DEMOLITION_SHIP,
        HEAVY_DEMO_SHIP,
        SIEGE_RAM,
        HEAVY_SCORPION
      ]
    },
    enabled: {
      units: [
        TURTLE_SHIP,
        ELITE_TURTLE_SHIP
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      WAR_WAGON,
      ELITE_WAR_WAGON,
      PANOKSEON,
      SHINKICHON
    ]
  },
  Lithuanians: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        PLATE_MAIL_ARMOR,
        SHIPWRIGHT,
        SIEGE_ENGINEERS,
        ARROWSLITS,
        SAPPERS,
        GOLD_SHAFT_MINING,
        SUPPLIES,
      ],
      units: [
        ARBALESTER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        SIEGE_RAM,
        SIEGE_ONAGER,
        HEAVY_SCORPION,
        HEAVY_DEMO_SHIP
      ]
    },
    unique: [
      LEITIS,
      ELITE_LEITIS,
      HILL_FORTS,
      TOWER_SHIELDS
    ]
  },
  Magyars: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER,
        FORTIFIED_WALL
      ],
      techs: [
        SQUIRES,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        FORTIFIED_WALL_TECH,
        REDEMPTION,
        ATONEMENT,
        FAITH,
        PLATE_MAIL_ARMOR,
        ARCHITECTURE,
        ARROWSLITS,
        STONE_SHAFT_MINING,
        GUILDS
      ],
      units: [
        HAND_CANNONEER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    unique: [
      MAGYAR_HUSZAR,
      ELITE_MAGYAR_HUSZAR,
      CORVINIAN_ARMY,
      RECURVE_BOW
    ]
  },
  Malay: {
    disabled: {
      buildings: [
        FORTIFIED_WALL
      ],
      techs: [
        PARTHIAN_TACTICS,
        BLOODLINES,
        FORTIFIED_WALL_TECH,
        FERVOR,
        THEOCRACY,
        HOARDINGS,
        CHAIN_BARDING_ARMOR,
        PLATE_BARDING_ARMOR,
        ARCHITECTURE,
        ARROWSLITS,
        TREADMILL_CRANE,
        TWO_MAN_SAW
      ],
      units: [
        HAND_CANNONEER,
        HEAVY_CAV_ARCHER,
        CHAMPION,
        HUSSAR,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        HEAVY_DEMO_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER
      ]
    },
    enabled: {
      units: [
        BATTLE_ELEPHANT,
        ELITE_BATTLE_ELEPHANT
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      KARAMBIT_WARRIOR,
      ELITE_KARAMBIT_WARRIOR,
      THALASSOCRACY,
      FORCED_LEVY
    ]
  },
  Malians: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        PARTHIAN_TACTICS,
        SHIPWRIGHT,
        BOMBARD_TOWER_TECH,
        BRACER,
        ILLUMINATION,
        BLAST_FURNACE,
        SIEGE_ENGINEERS,
        ARROWSLITS,
        TWO_MAN_SAW
      ],
      units: [
        HALBERDIER,
        HUSSAR,
        PALADIN,
        GALLEON,
        ELITE_CANNON_GALLEON,
        SIEGE_RAM,
        HEAVY_SCORPION
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      GBETO,
      ELITE_GBETO,
      TIGUI,
      FARIMBA
    ]
  },
  Mayans: {
    disableHorses: true,
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        BOMBARD_TOWER_TECH,
        REDEMPTION,
        ILLUMINATION,
        SIEGE_ENGINEERS,
        ARROWSLITS,
        GOLD_SHAFT_MINING,
        SUPPLIES,
      ],
      units: [
        HAND_CANNONEER,
        CHAMPION,
        CANNON_GALLEON,
        ELITE_CANNON_GALLEON,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    enabled: {
      units: [
        EAGLE_SCOUT,
        EAGLE_WARRIOR,
        ELITE_EAGLE_WARRIOR,
        XOLOTL_WARRIOR,
      ]
    },
    monkPrefix: MONK_PREFIX_MESO,
    unique: [
      PLUMED_ARCHER,
      ELITE_PLUMED_ARCHER,
      OBSIDIAN_ARROWS,
      EL_DORADO
    ]
  },
  Mongols: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        DRY_DOCK,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        REDEMPTION,
        ILLUMINATION,
        SANCTITY,
        BLOCK_PRINTING,
        THEOCRACY,
        RING_ARCHER_ARMOR,
        PLATE_BARDING_ARMOR,
        ARCHITECTURE,
        HEATED_SHOT,
        TREADMILL_CRANE,
        ARROWSLITS,
        TWO_MAN_SAW,
        GUILDS,
        CROP_ROTATION,
        SUPPLIES,
      ],
      units: [
        HAND_CANNONEER,
        HALBERDIER,
        PALADIN,
        ELITE_CANNON_GALLEON,
        BOMBARD_CANNON
      ]
    },
    enabled: {
      units: [
        STEPPE_LANCER,
        ELITE_STEPPE_LANCER
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      MANGUDAI,
      ELITE_MANGUDAI,
      NOMADS,
      DRILL
    ]
  },
  Persians: {
    disabled: {
      buildings: [
        FORTIFIED_WALL,
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        SHIPWRIGHT,
        FORTIFIED_WALL_TECH,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        REDEMPTION,
        ILLUMINATION,
        ATONEMENT,
        HERESY,
        SANCTITY,
        BRACER,
        SIEGE_ENGINEERS,
        ARROWSLITS,
        TREADMILL_CRANE
      ],
      units: [
        ARBALESTER,
        TWO_HANDED_SWORDSMAN,
        CHAMPION,
        SIEGE_ONAGER
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      WAR_ELEPHANT,
      ELITE_WAR_ELEPHANT,
      KAMANDARAN,
      MAHOUTS
    ]
  },
  Portuguese: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        SQUIRES,
        SHIPWRIGHT,
        ILLUMINATION,
        HOARDINGS,
        ARROWSLITS,
        GOLD_SHAFT_MINING
      ],
      units: [
        HEAVY_CAV_ARCHER,
        HUSSAR,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        FAST_FIRE_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER,
        HEAVY_SCORPION
      ]
    },
    enabled: {
      buildings: [
        FEITORIA
      ],
      units: [
        CARAVEL,
        ELITE_CARAVEL
      ]
    },
    unique: [
      ORGAN_GUN,
      ELITE_ORGAN_GUN,
      CARRACK,
      ARQUEBUS
    ]
  },
  Saracens: {
    disabled: {
      buildings: [
        BOMBARD_TOWER
      ],
      techs: [
        SHIPWRIGHT,
        BOMBARD_TOWER_TECH,
        SAPPERS,
        ARCHITECTURE,
        HEATED_SHOT,
        STONE_SHAFT_MINING,
        GUILDS,
        CROP_ROTATION
      ],
      units: [
        HALBERDIER,
        CAVALIER,
        PALADIN,
        FAST_FIRE_SHIP,
        HEAVY_SCORPION
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      MAMELUKE,
      ELITE_MAMELUKE,
      MADRASAH,
      ZEALOTRY
    ]
  },
  Slavs: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        SHIPWRIGHT,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        HERESY,
        BRACER,
        ARCHITECTURE,
        ARROWSLITS,
        HEATED_SHOT,
        STONE_SHAFT_MINING,
        GUILDS
      ],
      units: [
        ARBALESTER,
        HAND_CANNONEER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        ELITE_CANNON_GALLEON,
        HEAVY_DEMO_SHIP,
        BOMBARD_CANNON
      ]
    },
    unique: [
      BOYAR,
      ELITE_BOYAR,
      ORTHODOXY,
      DRUZHINA
    ]
  },
  Spanish: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        SIEGE_ENGINEERS,
        HEATED_SHOT,
        TREADMILL_CRANE,
        GOLD_SHAFT_MINING,
        CROP_ROTATION
      ],
      units: [
        CROSSBOWMAN,
        ARBALESTER,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        SIEGE_ONAGER,
        HEAVY_SCORPION
      ]
    },
    enabled: {
      units: [
        MISSIONARY
      ]
    },
    unique: [
      CONQUISTADOR,
      ELITE_CONQUISTADOR,
      INQUISITION,
      SUPREMACY
    ]
  },
  Tatars: {
    disabled: {
      buildings: [
        KEEP
      ],
      techs: [
        CHAIN_MAIL_ARMOR,
        PLATE_MAIL_ARMOR,
        SHIPWRIGHT,
        ARCHITECTURE,
        KEEP_TECH,
        ARROWSLITS,
        HOARDINGS,
        REDEMPTION,
        HERESY,
        SANCTITY,
        FAITH,
        THEOCRACY,
        STONE_SHAFT_MINING,
        TWO_MAN_SAW,
        SUPPLIES,
      ],
      units: [
        ARBALESTER,
        CHAMPION,
        PALADIN,
        SIEGE_ONAGER,
        BOMBARD_CANNON,
        HEAVY_DEMO_SHIP
      ]
    },
    enabled: {
      units: [
        STEPPE_LANCER,
        ELITE_STEPPE_LANCER,
        FLAMING_CAMEL,
      ]
    },
    unique: [
      KESHIK,
      ELITE_KESHIK,
      SILK_ARMOR,
      TIMURID_SIEGECRAFT
    ]
  },
  Teutons: {
    disabled: {
      techs: [
        THUMB_RING,
        PARTHIAN_TACTICS,
        HUSBANDRY,
        DRY_DOCK,
        SHIPWRIGHT,
        BRACER,
        ARCHITECTURE,
        GOLD_SHAFT_MINING
      ],
      units: [
        ARBALESTER,
        HEAVY_CAV_ARCHER,
        LIGHT_CAVALRY,
        HUSSAR,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        ELITE_CANNON_GALLEON,
        SIEGE_RAM
      ]
    },
    unique: [
      TEUTONIC_KNIGHT,
      ELITE_TEUTONIC_KNIGHT,
      IRONCLAD,
      CRENELLATIONS
    ]
  },
  Turks: {
    disabled: {
      techs: [
        HERBAL_MEDICINE,
        ILLUMINATION,
        BLOCK_PRINTING,
        STONE_SHAFT_MINING,
        CROP_ROTATION,
        SIEGE_ENGINEERS
      ],
      units: [
        ARBALESTER,
        ELITE_SKIRMISHER,
        PIKEMAN,
        HALBERDIER,
        PALADIN,
        FAST_FIRE_SHIP,
        ONAGER,
        SIEGE_ONAGER
      ]
    },
    monkPrefix: MONK_PREFIX_AFRICAN,
    unique: [
      JANISSARY,
      ELITE_JANISSARY,
      SIPAHI,
      ARTILLERY
    ]
  },
  Vietnamese: {
    disabled: {
      techs: [
        PARTHIAN_TACTICS,
        SHIPWRIGHT,
        REDEMPTION,
        HERESY,
        FERVOR,
        BLAST_FURNACE,
        MASONRY,
        ARCHITECTURE,
        GOLD_SHAFT_MINING
      ],
      units: [
        HAND_CANNONEER,
        HUSSAR,
        PALADIN,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        FAST_FIRE_SHIP,
        SIEGE_RAM,
        SIEGE_ONAGER,
        HEAVY_SCORPION
      ]
    },
    enabled: {
      units: [
        BATTLE_ELEPHANT,
        ELITE_BATTLE_ELEPHANT,
        IMPERIAL_SKIRMISHER
      ]
    },
    monkPrefix: MONK_PREFIX_ASIAN,
    unique: [
      RATTAN_ARCHER,
      ELITE_RATTAN_ARCHER,
      CHATRAS,
      PAPER_MONEY
    ]
  },
  Vikings: {
    disabled: {
      buildings: [
        KEEP,
        BOMBARD_TOWER
      ],
      techs: [
        PARTHIAN_TACTICS,
        BLOODLINES,
        HUSBANDRY,
        SHIPWRIGHT,
        KEEP_TECH,
        BOMBARD_TOWER_TECH,
        REDEMPTION,
        HERBAL_MEDICINE,
        SANCTITY,
        ILLUMINATION,
        THEOCRACY,
        PLATE_BARDING_ARMOR,
        STONE_SHAFT_MINING,
        GUILDS
      ],
      units: [
        HAND_CANNONEER,
        HEAVY_CAV_ARCHER,
        HALBERDIER,
        HUSSAR,
        CAMEL_RIDER,
        HEAVY_CAMEL_RIDER,
        PALADIN,
        FIRE_GALLEY,
        FIRE_SHIP,
        FAST_FIRE_SHIP,
        SIEGE_ONAGER,
        BOMBARD_CANNON
      ]
    },
    enabled: {
      units: [
        LONGBOAT,
        ELITE_LONGBOAT
      ]
    },
    unique: [
      BERSERK,
      ELITE_BERSERK,
      CHIEFTAINS,
      BERSERKERGANG
    ]
  }
};


